import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Spin, Button, Input, Popconfirm, Icon } from 'antd';
import { gloDataGet, gloDataPost } from './Common.js';

const TAG = 'TicketsPage';
const { Search } = Input;

export default class TicketsPage extends Component {

    constructor() {
        super();
        this.state = {
            importLoading: false,
            id: 0
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${TAG} componentDidMount`);
    }

    importData = () => {
        this.setState(
            { continuumDataLoading: true, continuumData: [] },
            () => gloDataGet(TAG, `continuum/data/index`, this.onContinuumDataLoaded)
        );
    }

    onDataImported = (pData) => {
        console.log(`${TAG} onDataImported`, pData);
        this.setState({ importLoading: false });
    }

    stripHtml = (text) => {
        let newText = text.split('#').map((item, i) => {
            return <p key={i}>{item}</p>;
        });
        return newText;
    }

    onChange = ({ target: { value } }) => {
        this.setState({ id: value });
    };

    importTicket = (p_id) => {
        console.log(`${TAG} importTicket`, p_id);
        let url = `continuum/data/import/${p_id}`;

        this.setState({ importLoading: true }, () => { gloDataGet(TAG, url, this.onDataImported) });
    }

    render() {
        return (
            <div className="main-content">
                {this.state.importLoading
                    ? <Spin size="large" style={{ marginTop: "20%" }} />
                    :
                    <div>
                        <h2 className="page-title">Test</h2>
                        <div className="wide-table-content" style={{ textAlign: 'left' }}>
                            <Search style={{ width: 300 }} enterButton="Import" size="large" onSearch={value => {console.log(value); this.importTicket(value)}} />
                        </div>
                    </div>
                }
            </div>
        );
    }
}