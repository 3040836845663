import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Table } from 'antd';
import { gloDataGet } from './Common.js';

const TAG = 'MainPage';

export default class MainPage extends Component {

    constructor() {
        super();
        this.state = {
            continuumData: [],
            continuumDataLoading: false,
            continuumSites: [],
            continuumSitesloading: false,
            visionTickets: [],
            visionTicketsLoading: false,
            redeskHistory: [],
            redeskTickets: [],
        }
    }

    componentDidMount(props) {
        console.log(`${TAG} componentDidMount`);
        this.loadContinuumData();
        this.loadVisionSites();
        this.loadContinuumSites();
    }

    loadContinuumData = () => {
        gloDataGet(TAG, `continuum/data`, this.onContinuumDataLoaded)
    }

    onContinuumDataLoaded = (pData) => {
        console.log(`${TAG} onContDataLoaded`, pData);
        if (pData) {
            this.setState({ continuumData: pData.data });
        }
    }

    loadContinuumSites = () => {
        gloDataGet(TAG, `continuum/sites`, this.onContinuumSitesLoaded)
    }

    onContinuumSitesLoaded = (pData) => {
        console.log(`${TAG} onContinuumSitesLoaded`, pData);
        if (pData) {
           // this.setState({ continuumSites: pData.data });
        }
    }

    loadVisionSites = () => {
        gloDataGet(TAG, `vision/sites`, this.onVisionSitesLoaded)
    }

    onVisionSitesLoaded = (pData) => {
        console.log(`${TAG} onVisionSitesLoaded`, pData);
        if (pData) {
            //this.setState({ visionTickets: pData.data });
        }
    }    

    render() {

        const columnsContData = [
            { title: 'id', dataIndex: 'id' },
            { title: 'data', dataIndex: 'cont_data' },
            { title: 'time', dataIndex: 'created_at' },
        ];
        return (
            <div>

                <Table
                    rowKey={record => record.id}
                    size="small"
                    showHeader={true}
                    columns={columnsContData}
                    loading={this.state.continuumDataLoading}
                    dataSource={this.state.contData}
                />

            </div>
        );
    }
}