import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Popconfirm } from 'antd';
import { gloDataGet, gloDataPost } from './Common.js';

import ContinuumSiteCard from "./ContinuumSiteCard";
import VisionSiteCard from "./VisionSiteCard";

const TAG = 'SitesPage';

export default class SitesPage extends Component {

    constructor() {
        super();
        this.state = {
            redeskHistory: [],
            continuumSites: [],
            continuumSitesloading: false,

            selectedContinuumId: 0,
            selectedContinuumTitle: 'Unknown',

            visionSites: [],
            visionSitesloading: false,

            selectedVisionId: 0,
            selectedVisionTitle: 'Unknown'

        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${TAG} componentDidMount`);
        this.loadData();
    }

    loadData = () => {
        this.loadContinuumSites();
        this.loadVisionSites();
    }

    loadContinuumSites = () => {
        this.setState( 
            { continuumSitesloading: true, continuumSites: [] }, 
            () => gloDataGet(TAG, `continuum/sites/index`, this.onContinuumSitesLoaded)
        );
    }

    reloadContinuumSites = () => {
        this.setState( 
            { continuumSitesloading: true, continuumSites: [] }, 
            () => gloDataGet(TAG, `continuum/sites/reload`, this.onContinuumSitesReloaded)
        );
    }

    onContinuumSitesLoaded = (pData) => {
        console.log(`${TAG} onContinuumSitesLoaded`, pData);
        if (pData) {
            this.setState({ 
                continuumSitesloading: false,
                continuumSites: pData.data 
            });
        } else {
            this.setState({ 
                continuumSitesloading: false,
                continuumSites: [] 
            });
        }
    }

    onContinuumSitesReloaded = (pData) => {
        this.setState({ continuumSitesloading: true, continuumSites: [] }, () => {
            this.loadContinuumSites()
        });
    }

    onSelectContinuum = (pId, pTitle) => {
        this.setState({
            selectedContinuumId: pId,
            selectedContinuumTitle: pTitle
        });
    }

    loadVisionSites = () => {
        this.setState( 
            { visionSitesloading: true, visionSites: [] }, 
            () => gloDataGet(TAG, `vision/sites/index`, this.onVisionSitesLoaded)
        );
    }

    reloadVisionSites = () => {
        this.setState( 
            { visionSitesloading: true, visionSites: [] }, 
            () => gloDataGet(TAG, `vision/sites/reload`, this.onVisionSitesReloaded)
        );
    }

    onVisionSitesLoaded = (pData) => {
        console.log(`${TAG} onVisionSitesLoaded`, pData);
        if (pData) {
            this.setState({ 
                visionSitesloading: false, 
                visionSites: pData.data 
            });
        } else {
            this.setState({ 
                visionSitesloading: false, 
                visionSites: [] 
            });
        }
    }

    onVisionSitesReloaded = (pData) => {
        this.setState({ visionSitesloading: false, visionSites: [] }, () => {
            this.loadVisionSites()
        });
    }

    onSelectVision = (pId, pTitle) => {
        this.setState({
            selectedVisionId: pId,
            selectedVisionTitle: pTitle
        });

    }

    mapContinuumVision = () => {
        if (this.state.selectedContinuumId > 0 && this.state.selectedVisionId > 0) {
            let payload = {
                continuum_site_id: this.state.selectedContinuumId,
                continuum_site_title: this.state.selectedContinuumTitle,
                vision_client_id: this.state.selectedVisionId,
                vision_client_title: this.state.selectedVisionTitle
            };   
            let url = 'map/continuum_vision';             
            gloDataPost(TAG, url, payload, this.onContinuumVisionMap, 'Done')
        }
    }

    onContinuumVisionMap = () => {
        this.setState({
            continuumSites: [],
            continuumSitesloading: false,

            selectedContinuumId: 0,
            selectedContinuumTitle: 'Unknown',

            visionSites: [],
            visionSitesloading: false,

            selectedVisionId: 0,
            selectedVisionTitle: 'Unknown'
        }, () => {
            this.loadData();
        });
    }

    render() {

        console.log(`${TAG} render`, this.state);
        const columnsContinuumSites = [
            {
                title: 'Site', key: 'name', render: x => (
                    <ContinuumSiteCard
                        selected_id={this.state.selectedContinuumId}
                        site={x.data}
                        site_id={x.site_id}
                        relative_id={x.vision_client_id}
                        relative_title={x.vision_client_title}
                        onselect={this.onSelectContinuum}
                    />
                )
            },
        ];

        const columnsVisionSites = [
            {
                title: 'Site', key: 'name', render: x => (
                    <VisionSiteCard
                        selected_id={this.state.selectedVisionId}
                        site={x.data}
                        site_id={x.site_id}
                        relative_id={x.continuum_site_id}
                        relative_title={x.continuum_site_title}
                        onselect={this.onSelectVision}
                    />
                )
            },
        ];

        const confirmText = "Are you sure?";

        return (
            <div className="main-content">
                <h2 className="page-title">Sites</h2>

                <div className="site-mapping">
                    <span className="table-title">Map Vision <b>{this.state.selectedVisionTitle}</b> to Continuum <b>{this.state.selectedContinuumTitle}</b></span>

                    <Popconfirm
                        placement="bottom"
                        title={confirmText}
                        onConfirm={this.mapContinuumVision}
                        okText="Yes"
                        cancelText="No"
                    >

                    {this.state.selectedContinuumId > 0 && this.state.selectedVisionId > 0
                        ? <Button type="primary" icon="api">Map</Button>
                        : <Button disabled type="primary" icon="api">Map</Button>
                    }

                    </Popconfirm>

                </div>

                <div className="table-content">
                    <div className="table-header">
                        <span className="table-title">Vision clients</span><Button type="primary" icon="reload" onClick={this.reloadVisionSites}>Reload</Button>
                    </div>
                    <Table
                        rowKey={record => record.site_id}
                        size="small"
                        showHeader={false}
                        columns={columnsVisionSites}
                        loading={this.state.visionSitesloading}
                        dataSource={this.state.visionSites}
                    />
                </div>
                <div className="table-content">
                    <div className="table-header">
                        <span className="table-title">Continuum sites</span><Button type="primary" icon="reload" onClick={this.reloadContinuumSites}>Reload</Button>
                    </div>
                    <Table
                        rowKey={record => record.site_id}
                        size="small"
                        showHeader={false}
                        columns={columnsContinuumSites}
                        loading={this.state.continuumSitesloading}
                        dataSource={this.state.continuumSites}
                    />
                </div>
            </div>
        );
    }
}