import Axios from 'axios';
import React from 'react';
import { message } from 'antd';
import { bool } from 'prop-types';

export const gloDataUrl = "https://support-mw.digitalagesolutions.com/data/public/api"
export const gloRootUrl = "https://support-mw.digitalagesolutions.com/data/public"

//export const gloDataUrl = "http://redeskauth/api"
//export const gloRootUrl = "http://redeskauth"

//export const gloDataUrl = "http://redesk/api"
//export const gloRootUrl = "http://redesk"

export function gloDataGet(pTag, pUrl, pFunc) {
    console.log(`${pTag} gloDataGet pUrl`, pUrl,localStorage["appState"]);
    //Axios.defaults.headers.get['Content-Type'] ='application/x-www-form-urlencoded';
    let token = localStorage["appState"] ? 
        JSON.parse(localStorage["appState"]).user ? JSON.parse(localStorage["appState"]).user.auth_token : "" : "";

    let config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
    
    //logOff();

    Axios.get(`${gloDataUrl}/${pUrl}?token=${token}`, config)
        .then((res) => {
            console.log(`${pTag} gloDataGet res`, res);
            pFunc(res)
        }).catch((error) => {
            console.log(`${pTag} gloDataGet error`, error);
            if (error.response) {
                if (error.response.status === 401) {
                    console.log(`${pTag} gloDataGet unauthorized`);
                    logOff();
                    pFunc(undefined);
                }
            }
            gloHandleError(error)
        });
}

export function gloLogin(pTag, pUrl, pPayload, pFunc, pMessage, pErrorMessage) {
    console.log(`${pTag} gloLogin pUrl`, pUrl);

    let config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }
    
    //logOff();

    Axios.post(`${gloDataUrl}/${pUrl}`, pPayload)
        .then((res) => {
            console.log(`${pTag} gloLogin res`, res);
            let logged = false;
            if (res) {
                if (res.data.success) {
                    logged = true;
                    
                }
            }
            if (logged) {
                pFunc(res)
            } else {
                message.error('Unable to login');
                pFunc(undefined);
            }
        }).catch((error) => {
            console.log(`${pTag} gloLogin error`, error);
            if (error.response) {
                if (error.response.status === 401) {
                    console.log(`${pTag} gloLogin unauthorized`);
                    logOff();
                }
            }
            pFunc(undefined);
            gloHandleError(error)
        });
}

function logOff() {
    let appState = {
        isLoggedIn: false,
        user: {}
    };
    // save app state with user date in local storage
    localStorage["appState"] = JSON.stringify(appState);    
}

export function gloDataPost(pTag, pUrl, pPayload, pFunc, pMessage, pErrorMessage) {
    console.log(`${pTag} gloDataPost started`, `${gloDataUrl}/${pUrl}`, pPayload);
    let token = localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "";

    let config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    Axios.post(`${gloDataUrl}/${pUrl}?token=${token}`, pPayload)
        .then((res) => {
            console.log(` .. ${pTag} gloDataPost result`, res);
            gloHandleSuccess(pMessage);
            pFunc(res);
        }).catch((error) => {

            console.log(`${pTag} gloDataGet error`, error);
            if (error.response) {
                if (error.response.status === 401) {
                    console.log(`${pTag} gloDataGet unauthorized`);
                    logOff();
                }
            }
                        
            if (pErrorMessage) {
                message.error(pErrorMessage);
            } else {
                gloHandleError(error)
            }
        })
}

export function gloDataPost2(pTag, pUrl, pPayload, pFunc, pMessage, pErrorMessage) {
    console.log(`${pTag} gloDataPost started`, `${gloDataUrl}/${pUrl}`);
    let token = localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "";

    let config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    Axios.post(`${gloDataUrl}/${pUrl}`, pPayload)
        .then((res) => {
            console.log(` .. ${pTag} gloDataPost result`, res);
            gloHandleSuccess(pMessage);
            pFunc(res);
        }).catch((error) => {
            if (pErrorMessage) {
                message.error(pErrorMessage);
            } else {
                gloHandleError(error)
            }
        })
}
/*
export function gloDataPost2(pTag, pUrl, pPayload, pFunc, pMessage) {
    console.log(`${pTag} gloDataPost started`, `${gloDataUrl}/${pUrl}`);

    let config = {
        headers: {
            'Content-Type': 'application/json',
        }
    }

    let token = localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "";

    Axios.post(`${gloDataUrl}/${pUrl}`, pPayload)
        .then((res) => {
            console.log(` .. ${pTag} gloDataPost result`, res);
            gloHandleSuccess(pMessage);
            pFunc(res);
        }).catch((error) => {
            if (pErrorMessage) {
                message.error(pErrorMessage);
            } else {
                gloHandleError(error)
            }

            pFunc(null);
        })
}
*/
export function gloHandleSuccess(pMessage) {
    if (typeof pMessage !== 'undefined') {
        message.success(pMessage);
    } else {
        message.success('Done');
    }
}

export function gloHandleError(pError) {
    console.log("gloHandleError1", pError.message);
    message.error(pError.message);
    console.log("gloHandleError2", pError);
    /*    
        if (pError.response) {
            if (pError.response.status === 401) {
                window.ppaComponent._logoutUser();
            }
        } else {
            window.ppaComponent._logoutUser();
        }
    */
}

