import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Popconfirm, Input } from 'antd';
import { gloDataGet, gloDataPost } from './Common.js';

import ContinuumSiteCard from "./ContinuumSiteCard";
import VisionSiteCard from "./VisionSiteCard";

const TAG = 'SettingsPage';

const { TextArea } = Input;

export default class SitesPage extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            first_name: "",
            last_name: "",
            ticket_filter: "",
            ticket_update_filter: ""
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${TAG} componentDidMount`);
        this.loadData();
    }

    loadData = () => {
        gloDataGet(TAG, `settings`, this.onDataLoaded)
    }

    onDataLoaded = (pData) => {
        console.log(`${TAG} onDataLoaded`, pData);
        if (pData) {
            this.setState({
                email: pData.data[0].default_contact_email,
                first_name: pData.data[0].default_contact_first_name,
                last_name: pData.data[0].default_contact_last_name,
                ticket_filter: pData.data[0].ticket_filter,
                ticket_update_filter: pData.data[0].ticket_update_filter
            });
        }
    }

    save = () => {
        let payload = {
            default_contact_email: this.state.email,
            default_contact_first_name: this.state.first_name,
            default_contact_last_name: this.state.last_name,
            ticket_filter: this.state.ticket_filter,
            ticket_update_filter: this.state.ticket_update_filter
        };
        let url = 'settings';
        gloDataPost(TAG, url, payload, this.onSaved, 'Done')
    }

    onSaved = () => {

    }

    handleEmailChange = (e) => {
        console.log(`${TAG} handleEmailChange`, e.target.value);
        this.setState({ email: e.target.value });
    };

    handleFirstNameChange = (e) => {
        console.log(`${TAG} handleFirstNameChange`, e.target.value);
        this.setState({ first_name: e.target.value });
    };
    handleLastNameChange = (e) => {
        console.log(`${TAG} handleLastNameChange`, e.target.value);
        this.setState({ last_name: e.target.value });
    };
    handleTicketFilterChange = (e) => {
        console.log(`${TAG} handleTicketFilterChange`, e.target.value);
        this.setState({ ticket_filter: e.target.value });
    }
    handleTicketUpdateFilterChange = (e) => {
        console.log(`${TAG} handleTicketUpdateFilterChange`, e.target.value);
        this.setState({ ticket_update_filter: e.target.value });
    }

    render() {

        console.log(`${TAG} render`, this.state);

        return (
            <div className="main-content">
                <h2 className="page-title">Settings</h2>

                <div className="page-row">
                    <span className="table-title" style={{ width: 200, display: 'inline-block' }}>Default contact email:</span><Input style={{ width: 300 }} value={this.state.email} placeholder="Default contact email" onChange={this.handleEmailChange} />
                </div><br />
                <div className="page-row">
                    <span className="table-title" style={{ width: 200, display: 'inline-block' }}>Default contact first name:</span><Input style={{ width: 300 }} value={this.state.first_name} placeholder="Default contact first name" onChange={this.handleFirstNameChange} />
                </div><br />
                <div className="page-row">
                    <span className="table-title" style={{ width: 200, display: 'inline-block' }}>Default contact last name:</span><Input style={{ width: 300 }} value={this.state.last_name} placeholder="Default contact last name" onChange={this.handleLastNameChange} />
                </div><br />
                <div className="page-row">
                    <span className="table-title" style={{ width: 200, display: 'inline-block', verticalAlign: "top" }}>Ticket create filters (delimited by semicolon):</span><TextArea rows={4} style={{ width: 300 }} value={this.state.ticket_filter} onChange={this.handleTicketFilterChange} />
                </div><br />
                <div className="page-row">
                    <span className="table-title" style={{ width: 200, display: 'inline-block', verticalAlign: "top" }}>Ticket update filters (delimited by semicolon):</span><TextArea rows={4} style={{ width: 300 }} value={this.state.ticket_update_filter} onChange={this.handleTicketUpdateFilterChange} />
                </div><br />
                <div className="page-row">
                    <Button type="primary" icon="save" onClick={this.save}>Save</Button>
                </div>

            </div>
        );
    }
}