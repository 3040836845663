import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Table, Popconfirm, Icon } from 'antd';
import { gloDataGet, gloDataPost } from './Common.js';

const TAG = 'TicketsPage';

export default class TicketsPage extends Component {

    constructor() {
        super();
        this.state = {
            redeskHistory: [],
            continuumData: [],
            continuumDataLoading: false,

        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${TAG} componentDidMount`);
        this.loadContinuumData();
    }

    loadContinuumData = () => {
        this.setState(
            { continuumDataLoading: true, continuumData: [] },
            () => gloDataGet(TAG, `continuum/data/index`, this.onContinuumDataLoaded)
        );
    }

    onContinuumDataLoaded = (pData) => {
        console.log(`${TAG} onContinuumDataLoaded`, pData);
        if (pData) {
            this.setState({ continuumDataLoading: false, continuumData: pData.data });
        } else {
            this.setState({ continuumDataLoading: false, continuumData: [] });
        }
    }

    reloadContinuumData = () => {

        this.setState(
            { continuumDataLoading: true, continuumData: [] },
            () => gloDataGet(TAG, `continuum/data/reload`, this.onContinuumDataReloaded)
        );

    }

    onContinuumDataReloaded = () => {
        this.setState({ continuumDataLoading: true, continuumData: [] }, () => {
            this.loadContinuumData()
        });
    }

    importVision = () => {
        gloDataGet(TAG, `vision/data/import`, this.onVisionImported)
    }

    onVisionImported = (pData) => {
        console.log(`${TAG} onVisionImported`, pData);
        this.setState({ continuumData: [] }, () => {
            this.loadContinuumData()
        });
    }

    stripHtml = (text) => {
        let newText = text.split('#').map((item, i) => {
            return <p key={i}>{item}</p>;
        });        
        return newText;
    }

    importTicket = (ticket) => {
        console.log(`${TAG} importTicket`, ticket);
        if (ticket) {
            let url = `continuum/data/import/${ticket.continuum_ticket_id}`;             
            gloDataGet(TAG, url, this.onTicketImported)
        }
    }

    onTicketImported = () => {
        this.loadContinuumData();
    }

    render() {

        const columnsContinuumData = [
            { title: 'ticket id', dataIndex: 'continuum_ticket_id' },
            {
                title: 'title', render: x => (
                    x.message
                        ? this.stripHtml(x.title)
                        : ""
                )
            },
            {
                title: 'message', render: x => (
                    x.message
                        ? this.stripHtml(x.message)
                        : "Not processed"
                )
            },
            {
                title: 'updated', render: x => (
                    x.message
                        ? this.stripHtml(x.updated_dt)
                        : "Not processed"
                )
            },
            { title: 'action', render: x => (
                <Popconfirm
                    placement="left"
                    title={"Are you sure?"}
                    onConfirm={() => this.importTicket(x)}
                    okText="Yes"
                    cancelText="No">

                    <a><Icon type="cloud-upload" /> Import</a>
                </Popconfirm>

            )},
        ];
        return (
            <div className="main-content">
                <h2 className="page-title">Tickets</h2>

                <div className="wide-table-content">
                    <Table
                        rowKey={record => record.continuum_ticket_id}
                        size="small"
                        showHeader={true}
                        columns={columnsContinuumData}
                        loading={this.state.continuumDataLoading}
                        dataSource={this.state.continuumData}

                    />
                </div>
            </div>
        );
    }
}