import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Icon } from 'antd';
import { gloDataGet } from './Common.js';

const TAG = 'ContinuumSiteCard';

export default class ContinuumSiteCard extends Component {

    constructor(props) {
        super(props);
        console.log(`${TAG} render`, props);
        this.state = {
            selected_id: props.selected_id,
            site: JSON.parse(props.site),
            site_id: props.site_id,
            relative_id: props.relative_id,
            relative_title: props.relative_title
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${TAG} componentDidMount`);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selected_id: nextProps.selected_id });
    }

    onSelect = () => {

        console.log(`${TAG} onSelect`, this.state);
        let title = `${this.state.site.nameField} [${this.state.site.codeField}]`;
        this.props.onselect(this.state.site_id, title);
    }

    render() {
        let cardClass = this.state.relative_id ? 'site-mapped' : '';
        if (this.state.selected_id === this.state.site_id) { 
            cardClass += " site-mapped-selected";
        }
//        let cardStyle = (this.state.selected_id === this.state.site_id) ? {borderWidth: 2} : {borderWidth: 1};

        return (
            <Card 
                size="small" 
                title={`${this.state.site.nameField} [${this.state.site.codeField}]`} 
                extra={<a href="#" onClick={() => this.onSelect()}>Select</a>} 
                className={cardClass}>
                
                {this.state.site.countryField}<br/>
                {this.state.site.stateField}<br/>
                {this.state.site.address1Field} {this.state.site.address2Field}

                {this.state.relative_title && this.state.relative_title.length > 0
                    ? <p><b>mapped to {this.state.relative_title}</b></p>
                    : ''
                }

                <div className="site-id">#{this.state.site_id}</div>

            </Card>
        );
    }
}