import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Icon, Collapse } from 'antd';
import { gloDataGet } from './Common.js';

const TAG = 'VisionSiteCard';
const { Panel } = Collapse;

export default class VisionSiteCard extends Component {

    constructor(props) {
        super(props);
        console.log(`${TAG} render`, props);
        this.state = {
            selected_id: props.selected_id,
            site: JSON.parse(props.site),
            site_id: props.site_id,
            relative_id: props.relative_id,
            relative_title: props.relative_title,
            is_contacts_loading: false,
            cintacts: []
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${TAG} componentDidMount`);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ selected_id: nextProps.selected_id });
    }

    onSelect = () => {

        console.log(`${TAG} onSelect`, this.state);
        let title = this.getName();
        this.props.onselect(this.state.site_id, title);
    }

    getName = () => {
        let name = this.state.site.last_name && this.state.site.last_name.length > 0
            ? `${this.state.site.first_name} ${this.state.site.last_name}`
            : `${this.state.site.first_name}`;

        return this.state.site.office_name && this.state.site.office_name.length > 0
            ? `${this.state.site.office_name} [${name}]`
            : `${name}`;
    }

    loadContacts = () => {
        this.setState( 
            { is_contacts_loading: true, contacts: [] }, 
            () => gloDataGet(TAG, `vision/contacts/index/${this.state.site_id}`, this.onContactsLoaded)
        );

    }

    getContacts = () => {
        if (this.state.contacts && this.state.contacts.length > 0) {
            return this.state.contacts.map(x => (
                <li key={x.client_id}>
                    {`${x.first_name} ${x.last_name} (${x.email})`}
                </li>
                ));
    
        } else {
            return "not found";
        }

    }

    onContactsLoaded = (pData) => {
        console.log(`${TAG} onContactsLoaded`, pData);
        if (pData) {
            this.setState({ 
                is_contacts_loading: false,
                contacts: pData.data 
            });
        } else {
            this.setState({ 
                is_contacts_loading: false,
                contacts: [] 
            });
        }
    }

    collapsed = (key) => {
        console.log(key);
    }

    contactsExtra = () => (
        <Button type="primary" shape="circle" icon="redo" size="small"
            onClick={event => {
                event.stopPropagation();
                console.log("contactsExtra clicked");
                this.loadContacts();
            }}
        />
    );

    contactsExtraLoading = () => (
        <Button type="primary" shape="circle" icon="redo" size="small" loading />
    );    

    render() {
        let cardClass = this.state.relative_id ? 'site-mapped' : '';
        if (this.state.selected_id === this.state.site_id) {
            cardClass += " site-mapped-selected";
        }
        //        let cardStyle = (this.state.selected_id === this.state.site_id) ? {borderWidth: 2} : {borderWidth: 1};
        let address = this.state.site.permanent_address && this.state.site.permanent_address.length > 0
            ? this.state.site.permanent_address
            : this.state.site.temporary_address;

        let name = this.getName();

        return (
            <Card
                size="small"
                title={name}
                extra={<a href="#" onClick={() => this.onSelect()}>Select</a>}
                className={cardClass}>

                {this.state.site.country}<br />
                {this.state.site.state}<br />
                {this.state.site.city} {address}


                {this.state.relative_title && this.state.relative_title.length > 0
                    ? <p><b>mapped to {this.state.relative_title}</b></p>
                    : ''
                }

                <div className="site-id">#{this.state.site_id}</div>

                <Collapse onChange={this.collapsed} style={{ marginBottom: 20 }}>
                    <Panel header="Contacts" key="1" extra={this.state.is_contacts_loading ?  this.contactsExtraLoading() : this.contactsExtra()}>
                        <ul>{this.getContacts()}</ul>
                    </Panel>
                </Collapse>

            </Card>
        );
    }
}