import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import 'antd/dist/antd.css';
import { Layout, Menu, Icon, message } from 'antd';
import { gloLogin } from './Common.js';
import axios from 'axios';
import $ from 'jquery';
import Login from "./Login";

import { BrowserRouter, Switch, Route, Link, Router } from 'react-router-dom';

import MainPage from "./MainPage";
import SitesPage from "./SitesPage";
import TicketsPage from "./TicketsPage";
import SettingsPage from "./SettingsPage";
import TestPage from "./TestPage";

const { Header, Footer, Sider, Content } = Layout;

const Main = () => (
    this.state.component_id === 1
        ? <SitesPage />
        : <TicketsPage />
)

const componentName = "App"

class App extends Component {

    constructor() {
        super();
        this.state = {
            component_id: 1,
            isLoggedIn: false,
            user: {},
        };
    }

    componentDidMount() {
        this.checkToken();

        window.ppaComponent = this;
        console.log("App componentDidMount 2");
    }

    checkToken2 = () => {

        let token = localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "";
        console.log("App checkToken", token);
        //gloDataGet(componentName, `check`, this.onTokenChecked);

    }

    checkToken = () => {

        if (this.state.isLoggedIn) {
            let state = localStorage["appState"];
            //let token = localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "";
            console.log("App onTokenChecked 1");
            let appState = {
                isLoggedIn: false,
                user: {}
            };
            if (state) {
                let AppState = JSON.parse(state);
                console.log("onTokenChecked", AppState);
                appState = { isLoggedIn: AppState.isLoggedIn, user: AppState };
            } else {
                // save app state with user date in local storage
                localStorage["appState"] = JSON.stringify(appState);
                this.setState(appState);
            }
            if (appState.isLoggedIn !== this.state.isLoggedIn) {
                this.setState(appState);
            }
        }
    }

    _loginUser = (email, password) => {
        $("#login-form button")
            .attr("disabled", "disabled")
            .html(
                '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
            );
        let formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        gloLogin(componentName, `user/login`, formData, this.onLogin, "Login successful", "Unable to login");
    };

    onLogin = (json) => {
        if (json && json.data.success) {

            let userData = {
                name: json.data.data.name,
                id: json.data.data.id,
                email: json.data.data.email,
                auth_token: json.data.data.auth_token,
                timestamp: new Date().toString()
            };
            let appState = {
                isLoggedIn: true,
                user: userData
            };
            // save app state with user date in local storage
            localStorage["appState"] = JSON.stringify(appState);
            this.setState({
                isLoggedIn: appState.isLoggedIn,
                user: appState.user
            });
        } else {
            //alert("Login Failed!");
            //message
            this._logoutUser();
        }

        $("#login-form button")
            .removeAttr("disabled")
            .html("Login");
    }

    _logoutUser = () => {
        let appState = {
            isLoggedIn: false,
            user: {}
        };
        // save app state with user date in local storage
        localStorage["appState"] = JSON.stringify(appState);
        this.setState(appState);
    };

    setComponent = (id) => {
        this.setState({ component_id: id });
    }

    renderComponent = () => {
        switch (this.state.component_id) {
            case 1:
                return <SitesPage />;
            case 2:
                return <TicketsPage />;
            case 4:
                return <TestPage />;
            default:
                return <SettingsPage />;
        }
    }
//                                     <a onClick={() => this.setComponent(4)} style={{ paddingRight: 20 }}><Icon type="experiment" theme="twoTone" /> Test</a>

    render() {
        console.log("App props", this.state);
        this.checkToken();
        return (
            <div className="App">
                {this.state.isLoggedIn
                    ? <BrowserRouter>
                        <div className="App" style={{ height: '100%' }}>
                            <Layout style={{ height: '100%' }}>
                                <Header style={{ backgroundColor: 'transparent', textAlign: 'left' }}>
                                    <a onClick={() => this.setComponent(1)} style={{ paddingRight: 20 }}><Icon type="bank" theme="twoTone" /> Sites</a>
                                    <a onClick={() => this.setComponent(2)} style={{ paddingRight: 20 }}><Icon type="snippets" theme="twoTone" /> Tickets</a>
                                    <a onClick={() => this.setComponent(3)}><Icon type="setting" theme="twoTone" /> Settings</a>
                                </Header>
                                {this.renderComponent()}
                                <Footer>ReDesk v 0.1.08</Footer>
                            </Layout>
                        </div>
                    </BrowserRouter>
                    : <Login {...this.props} loginUser={this._loginUser} />
                }
            </div>
        );
    }

}

export default App;
