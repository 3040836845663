import React from "react";
import { Link } from "react-router-dom";

const Login = ({ history, loginUser = f => f }) => {
    let _email, _password;
    const handleLogin = e => {
        e.preventDefault();
        loginUser(_email.value, _password.value);
    };
    return (
        <div id="main" className="main">
            <form id="login-form" action="" onSubmit={handleLogin} method="post">
                <h1 style={{ padding: 15 }}>ReDesk</h1>
                <img src="/images/das_logo.png" style={{ width: 200 }} />
                <div style={{ marginTop: 40 }}>
                    <div style={{ marginBottom: 10 }}>
                        <span style={{ width: 100, display: 'inline-block', fontWeight: 'bold' }}>Name:</span>
                        <input ref={input => (_email = input)} autoComplete="off" id="email-input" name="email" type="text" className="center-block" placeholder="email" />
                    </div>
                    <div>
                        <span style={{ width: 100, display: 'inline-block', fontWeight: 'bold' }}>Password:</span>
                        <input ref={input => (_password = input)} autoComplete="off" id="password-input" name="password" type="password" className="center-block" placeholder="password" />
                    </div>
                </div>
                <button style={{ marginTop: 40 }} type="submit" className="btn-login-submit" id="email-login-btn" href="#facebook" >
                    Login
                </button>
            </form>
        </div>
    );
};

export default Login;